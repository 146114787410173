@use '@/styles/colors.module' as colors;
@use '@/styles/mixins.module' as mixins;

.dialog-overlay {
  @include mixins.dialog-overlay-style;
  z-index: 2000;
}

.dialog-container {
  @include mixins.modal-dialog-container-style;
}

.dialog-header {
  @include mixins.modal-dialog-header-style;
  white-space: pre-line;
}

.dialog-content {
  @include mixins.modal-dialog-content-style;
}

.dialog-buttons {
  @include mixins.modal-dialog-buttons;
}

.dialog-msg {
  @include mixins.modal-dialog-message;
  white-space: pre-line;
}
