@use '../../../styles/palettes.module' as palettes;

.auth-error {
  display: flex;
  margin: 80px 30px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  gap: 60px;

  & > p {
    margin: 0;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.auth-error-contents {
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  & p {
    text-align: center;
    font-size: 14px;
    margin: 0;
  }

  // ボタンの色と枠はページごとで違うため別途指定する
  & button {
    background: palettes.$palette-azure-radiance;
    border: 0px;
    border-radius: 5px;
    width: 100%;
    padding: 12px 20px;
    font-weight: 700;
  }
}
