@use '@/styles/palettes.module' as palettes;

.browser-web {
  background-color: palettes.$palette-true-black;
  height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  & > div:not(:empty) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
