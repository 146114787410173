@use './palettes.module' as palettes;

// オーバーレイの背景色
$color-default-overlay-bg: palettes.$palette-true-black;
// ダイアログ関連の基本背景色
$color-default-container-bg: palettes.$palette-true-white;

// === ヘッダー ===
$color-header: palettes.$palette-medium-gray;
$color-header-border: palettes.$palette-light-blue;
$color-header-slider-thumb: rgba(0, 0, 0, 0.4);

// === フッター ===
// 背景色
$color-footer-bg: palettes.$palette-lamp-black;
// ボタンアニメーション STT ON　影
$color-footer-btn-shadow: palettes.$palette-azure-radiance;
// ボタンアニメーション0% STT ON
$color-footer-btn-animation-stt-on: rgba(10, 132, 255, 0.4);
// ボタンアニメーション100% STT ON
$color-footer-btn-animation-pulsate-stt-on: rgba(10, 132, 255, 0.5);

// === ボタン ===
// 青ボタン(非活性)の色
$color-blue-button-disabled: rgba(255, 255, 255, 0.15);
// 青ボタン(非活性)の文字色
$color-blue-button-disabled-label: rgba(255, 255, 255, 0.2);

// === コンテンツ全般 ===
$color-contents-bg: palettes.$palette-wet-crow;

// === ポップアップ ===
// 背景色
$color-modal-popup-bg: rgba(41, 42, 45, 0.9);
// 背景色(ホバー時)
$color-modal-popup-bg-hover: rgba(0, 0, 0, 0.2);
// 文字色
$color-modal-popup-text: palettes.$palette-true-white;
// 枠線の色
$color-modal-popup-border: rgba(255, 255, 255, 0.16);
//

// ==== ダイアログ ====
// ボタンの色
$color-modal-dialog-button: #292a2d;
// ボタンの文字色
$color-modal-dialog-button-label: palettes.$palette-azure-radiance;
// Infoボタンの文字色
$color-modal-dialog-info-button-label: palettes.$palette-azure-radiance;
// Warningボタンの文字色
$color-modal-dialog-warning-button-label: palettes.$palette-coral-red;
// キャンセルボタンの文字色
$color-modal-dialog-chancel-button-label: palettes.$palette-azure-radiance;
// 文字色
$color-modal-dialog-text: palettes.$palette-true-white;
// 縁のみの文字色
$color-modal-dialog-bordered-text: palettes.$palette-dodger-blue;
// 背景色
$color-modal-dialog-bg: rgba(41, 42, 45);
// 線の色
$color-modal-dialog-border: rgba(255, 255, 255, 0.16);
// ダイアログの影色
$color-modal-dialog-shadow: palettes.$palette-boulder;
// ダイアログの枠線
$color-modal-dialog-frame-border: rgba(255, 255, 255, 0.04);
// 閉じるボタンホバー時の背景色
$color-modal-close-button-hover: rgba(0, 0, 0, 0.12);
// プルダウンdisabled時に表示するテキストの文字色
$color-modal-dialog-border: rgba(255, 255, 255, 0.6);
// プランダイアログのシリアル表示欄の背景色
$color-modal-serial-input: rgba(0, 0, 0, 0.08);

// ==== Spinner ====
// スピナーの色
$color-spinner-top: palettes.$palette-dark-dodger-blue;
$color-spinner: palettes.$palette-mercury;

// ==== Button ====
// ボタンの色
$color-button: palettes.$palette-azure-radiance;
// ボタンの枠線の色
$color-button-border: palettes.$palette-azure-radiance;
// ボタンの文字色
$color-button-label: palettes.$palette-true-white;

// ==== Select ====
// セレクトボックスの文字色
$color-select-letter: palettes.$palette-cerulean;

// ==== STT暫定テキスト ====
// STT暫定テキストの文字色
$color-list-view-interim-letter: palettes.$palette-cyan;
$color-list-view-scroll-bar: palettes.$palette-light-gray;

// ==== Toast ====
// 背景色
$color-toast-bg: rgba(41, 42, 45, 0.9);
// 文字色
$color-toast-text: palettes.$palette-true-white;

// ==== ようこそ画面 ====
// 背景色
$color-welcome-bg: palettes.$palette-gray;

// ==== 利用規約画面 ====
// 利用規約リンクの文字色
$color-agreement-link: palettes.$palette-azure-radiance;
// 同意しないボタンの線の色
$color-disagree-button-border: palettes.$palette-azure-radiance;

// ==== ホーム画面 ====
// 選択ボタンの説明の文字色
$color-home-button-explanation-text: palettes.$palette-silver-gray;
