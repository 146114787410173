@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;

.home {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  align-self: stretch;
  gap: 40px;
  flex: 1 0 0;
  overflow: auto;
  white-space: pre-line;
  overflow-wrap: break-word;
}

.select-translation {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.select-button {
  & .select-mic {
    width: 265px;
    height: 60px;
    background-color: colors.$color-button;
    background-size: cover;
  }

  & .select-speaker {
    width: 265px;
    height: 60px;
    background-color: colors.$color-button;
    background-size: cover;
  }

  & button {
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &:hover {
      cursor: pointer;
      filter: brightness(90%);
    }
  }
}

a {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: colors.$color-agreement-link;
  font-size: 1rem;
  line-height: 1.25rem;
}
