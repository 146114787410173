@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;
@use '../../../styles/styles.module' as styles;

.footer {
  width: 100%;
  color: palettes.$palette-true-white;
  background: colors.$color-footer-bg;
  border-radius: 14px 14px 0 0;
}

.footer button {
  background-color: colors.$color-button;
}

.footer-open {
  height: calc(styles.$footer-height + styles.$footer-button-height);
  transition: bottom 0.6s;
  bottom: 0;
  position: fixed;
  width: calc(100vw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
}

.footer-close {
  overflow: hidden;
  transition: bottom 0.6s;
  bottom: calc(-1 * styles.$footer-height + env(safe-area-inset-bottom));
  position: fixed;
  width: calc(100vw - (env(safe-area-inset-left) + env(safe-area-inset-right)));
  background: colors.$color-footer-bg;
}

.footer-container {
  border-top: 1px solid colors.$color-footer-bg;
  height: 230px;
  display: flex;
  justify-content: center;
}

.open {
  display: inline-block;
  transform: rotate(180deg);
}

.footer-close-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  & .stt-indicate-close {
    flex-grow: 1;
    position: relative;

    & > img {
      position: absolute;
      top: 40%;
      left: calc(50% - 14px);
    }
  }
}

.translation-footer {
  margin: 20px auto;
  max-width: calc(100% - 40px);
  height: 100%;

  @media screen and (max-width: styles.$mobile-max-width) {
    // スマホ用 フッターの横幅設定
    min-width: 300px;
    max-width: 350px;
    margin: 20px auto;
  }
}

.translation-button {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  gap: 8px;
}

.footer-translation-container {
  display: flex;
}

.footer-container-left {
  padding-right: 0.75rem;
  flex: 2;
  justify-content: flex-end;
}

.footer-container-right {
  padding-left: 0.75rem;
  flex: 2;
  justify-content: flex-start;
}

.footer-container-bottom {
  margin: 1rem 0 1.5rem 0;
}

.lang-list {
  margin-top: auto;
  height: 32px;
  display: flex;
  align-items: center;

  & img {
    height: 32px;
  }

  & select {
    color: colors.$color-select-letter;
    outline: none;
    border: none;
    background-color: colors.$color-footer-bg;
    text-align: center;
    text-overflow: ellipsis;
    height: 32px;
    font-size: 13px;
    line-height: 1.85;

    @media screen and (max-width: styles.$mobile-max-width) {
      // スマホ用 セレクトボックスの最大幅設定
      max-width: calc((styles.$footer-mobile-max-width - 33px - 1.5rem) / 2);
    }
  }

  & select:hover {
    cursor: pointer;
  }
}

.trial-message {
  text-align: center;

  & p {
    margin: 0;
    font-size: 12px;
    text-align: left;
    display: inline-block;
  }
}

.device-name {
  margin: 0;
  font-size: 12px;
  width: 100%;
  padding: 16px 0 8px 0;

  & p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5;

    & select {
      overflow: hidden;
      text-overflow: ellipsis;
      color: palettes.$palette-true-white;
      outline: none;
      border: none;
      background-color: colors.$color-footer-bg;

      & select:hover {
        cursor: pointer;
      }

      @media screen and (max-width: styles.$mobile-max-width) {
        // スマホ用 デバイス名セレクトボックスの最大幅/最小幅設定
        max-width: styles.$footer-mobile-max-width;
        min-width: styles.$footer-mobile-min-width;
      }
    }
  }
}

.stt-device {
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

button {
  background: transparent;
  border: none;
}

.img-btn {
  width: 64px;
  height: 64px;

  &:hover {
    cursor: pointer;
    filter: brightness(1.2);
  }
}

.mic-on {
  background-image: url('../../../assets/mic_on.svg');

  &:active {
    background-image: url('../../../assets/mic_on_push.svg');
  }
}

.mic-off {
  background-image: url('../../../assets/mic_off.svg');

  &:active {
    background-image: url('../../../assets/mic_off_push.svg');
  }
}

.ripple-btn-off {
  position: relative;
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 24px palettes.$palette-true-red;
  cursor: pointer;
}

.ripple-btn-on {
  position: relative;
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 24px colors.$color-footer-btn-shadow;
  cursor: pointer;
}

.ripple-btn-on::before,
.ripple-btn-on::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 8px solid colors.$color-footer-btn-animation-stt-on;
  border-radius: 50%;
  box-sizing: border-box;
  pointer-events: none;
  animation: pulsate 2.8s linear infinite;
}

.ripple-btn-on::after {
  animation-delay: 1.4s;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.7);
    opacity: 0;
    border: 1px solid colors.$color-footer-btn-animation-pulsate-stt-on;
    filter: brightness(70%);
  }
}

.menu {
  background: none;
  border: 0;
  padding: 0;
  line-height: 1;

  & img {
    width: 160px;
    transform: scale(0.25);
    margin: -60px;
  }

  &:hover {
    cursor: pointer;
  }
}

.menu-contents {
  background-color: colors.$color-modal-popup-bg;
  border-radius: 14px;
  backdrop-filter: blur(50px);

  @media screen and (max-width: 480px) {
    // スマホ用 メニューの横幅
    max-width: 320px;
  }

  & ul {
    padding-left: 0;

    & li {
      list-style: none;
      border-bottom: 1px solid colors.$color-modal-popup-border;

      &:first-child {
        border-radius: 14px 14px 0rem 0rem;
      }

      &:last-child {
        border-bottom: none;
        border-radius: 0rem 0rem 14px 14px;
      }

      & button {
        color: colors.$color-modal-popup-text;
        line-height: 1.5rem;
        font-size: 17px;
        display: flex;
        align-items: center;
        text-align: left;
        column-gap: 20px;
        padding: 12px 20px;
        width: 100%;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$color-modal-popup-bg-hover;
        }

        &:disabled {
          cursor: default;
          opacity: 0.3;
          background: palettes.$palette-true-black;
        }
      }
    }
  }
}
