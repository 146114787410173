@use '@/styles/palettes.module' as palettes;
@use '@/styles/colors.module' as colors;
@use '@/styles/styles.module' as styles;

header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.header-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  background-color: colors.$color-header;
  border-bottom: 4px solid colors.$color-header-border;
  height: styles.$header-height;
  box-sizing: border-box;
}

.header-logo {
  display: flex;
  width: 100%;
  height: 40px;
  padding-left: 4px;

  & > div {
    padding-top: 4px;
  }
}

.header-menu {
  display: flex;
}

.header-logo-img {
  max-width: 150px;
  height: 40px;
}

.back-btn {
  &:hover {
    cursor: pointer;
  }
}

.menu {
  background: none;
  border: 0;
  padding: 0;
  line-height: 1;

  & img {
    width: 160px;
    transform: scale(0.25);
    margin: -60px;
  }

  &:hover {
    cursor: pointer;
  }
}

.header-right-contents {
  display: flex;
  gap: 8px;
  margin-right: 8px;
}

.menu-contents {
  background-color: colors.$color-modal-popup-bg;
  border-radius: 14px;
  backdrop-filter: blur(50px);

  @media screen and (max-width: 480px) {
    // スマホ用 メニューの横幅
    max-width: 320px;
  }

  & ul {
    padding-left: 0;

    & li {
      list-style: none;
      border-bottom: 1px solid colors.$color-modal-popup-border;

      &:first-child {
        border-radius: 14px 14px 0rem 0rem;
      }

      &:last-child {
        border-bottom: none;
        border-radius: 0rem 0rem 14px 14px;
      }

      & button {
        color: colors.$color-modal-popup-text;
        line-height: 1.5rem;
        font-size: 17px;
        display: flex;
        align-items: center;
        text-align: left;
        column-gap: 20px;
        padding: 12px 20px;
        width: 100%;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background-color: colors.$color-modal-popup-bg-hover;
        }

        &:disabled {
          cursor: default;
          opacity: 0.3;
          background: palettes.$palette-true-black;
        }
      }
    }
  }
}

.font-size-slider {
  transform: translateX(-48px) rotate(-90deg);
  transform-origin: top right;
  border-radius: 24px;

  & > ul {
    width: 280px;
    height: 48px;
    display: flex;

    & > li {
      display: flex;
      align-items: center;
      width: fit-content;
      height: fit-content;
      margin: auto;
      padding: 0;
    }
  }
}

.input-range {
  transform: rotate(-360deg);
  transform-origin: top right;
  touch-action: pan-down;
  appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.32);
  height: 4px;
  width: 232px;
  border-radius: 2px;
  outline: 0;

  &::-webkit-slider-thumb {
    appearance: none;
    background: #fff;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &:hover {
      border: solid 4px colors.$color-header-slider-thumb;
      width: 24px;
      height: 24px;
    }
  }
}
