@use './colors.module' as colors;

// flex
@mixin flex {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
}

// appearance: none
@mixin appearance-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

// ボタンのスタイルリセット用
@mixin reset-button-style {
  @include appearance-none();
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

// ダイアログのオーバレイのスタイル
@mixin dialog-overlay-style {
  background-color: rgba($color: colors.$color-default-overlay-bg, $alpha: 0.5);
  backdrop-filter: blur(4px);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
}

// ダイアログ関連のスタイル
@mixin dialog-style {
  border-radius: 20px;
  background-color: colors.$color-default-container-bg;
}

// モーダルダイアログの基本スタイル
@mixin modal-dialog-container-style {
  overflow: hidden;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.16);
  background-clip: padding-box;
  background: transparent;
  background-color: colors.$color-modal-dialog-bg;
  user-select: none;
  margin: auto;
  border-radius: 14px;
  width: calc(100% - 40px);
  max-width: 400px;
  min-width: 320px;
  border: 1px solid colors.$color-modal-dialog-frame-border;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  $close-button-icon-size: 24px;
  $close-button-icon-top: 8px;
  .dialog-close-container {
    text-align: center;
  }

  .close-button {
    @include reset-button-style();
    cursor: pointer;
    height: calc(#{$close-button-icon-size} + #{$close-button-icon-top} * 2);
    width: calc(#{$close-button-icon-size} + #{$close-button-icon-top} * 2);
    border-radius: 50%;
    line-height: 0;
    padding: 8px;

    &:hover {
      background-color: colors.$color-modal-close-button-hover;
    }
  }

  // TODO: アイコン使うことになると思うので、色などのプロパティは削除する。
  .close-button-icon {
    display: block;
    position: relative;
    width: $close-button-icon-size;
    height: $close-button-icon-size;

    &::before,
    &::after {
      /* 共通設定 */
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 3px; /* 棒の幅（太さ） */
      height: $close-button-icon-size; /* 棒の高さ */
      background: #333;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

@mixin modal-dialog-header-style {
  @include flex();
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  text-align: center;

  p {
    font-size: 1.0625rem;
    font-weight: 700;
    line-height: 1.375rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0.5rem;
    color: colors.$color-modal-dialog-text;

    &.mod-center {
      text-align: center;
    }
  }

  .header {
    width: 100%;
  }

  .left {
    width: 100%;
    padding-left: 40px;
  }

  &.mod-close-btn {
    padding-right: 10px;
  }
}

@mixin modal-dialog-content-style {
  @include flex();
  flex-direction: column;
  box-sizing: border-box;
  background-clip: padding-box;
  text-align: left;
  line-height: 20px;
  min-height: 0;
  color: colors.$color-modal-dialog-text;

  .content-primary {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
  }
}

@mixin modal-dialog-buttons {
  width: 100%;
  @include flex();
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0;
  border-top: 1px solid colors.$color-modal-dialog-border;

  &.mod-right {
    justify-content: flex-end;
  }

  .multi-buttons {
    max-width: 50%;
  }

  button {
    cursor: pointer;

    &.dialog-button {
      background: none;
      border-width: 0;
      padding: 12px 16px;
      color: colors.$color-modal-dialog-button-label;
      font-weight: 700;
      font-size: 1.0625rem;
      line-height: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      &:hover {
        background-color: colors.$color-modal-close-button-hover;
      }

      &:active {
        opacity: 0.2;
      }

      &:disabled {
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
      }

      &.mod-horizontal {
        flex-grow: 1;
      }

      &.mod-bordered {
        border-right: 1px solid colors.$color-modal-dialog-border;
      }

      &.info {
        color: colors.$color-modal-dialog-info-button-label;
      }

      &.warning {
        color: colors.$color-modal-dialog-warning-button-label;
      }

      &.chancel {
        color: colors.$color-modal-dialog-chancel-button-label;
        font-style: normal;
        font-weight: 400;
      }

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
// モーダルダイアログ内に表示するメッセージのスタイル
@mixin modal-dialog-message {
  padding: 0 0.75rem 20px 0.75rem;
}

// スピナーのオーバーレイのスタイル
@mixin spinner-overlay-style {
  background-color: rgba($color: colors.$color-default-overlay-bg, $alpha: 0.3);
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 20000;
}

// スピナーのスタイル
@mixin spinner-style($spinner-color, $spinner-top-color) {
  &,
  &::after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  z-index: 20000;
  position: absolute;
  top: 50%;
  left: 50%;
  border: 4px $spinner-color solid;
  border-top: 4px $spinner-top-color solid;
  animation: spin 1s infinite linear;

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
}
