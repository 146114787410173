// === 全般設定 ===
// 横幅最大値(mobile)
$mobile-max-width: 767px;

// === ヘッダー ===
// ヘッダーの高さ
$header-height: 62px;

// === フッター===
// フッター自体の高さ
$footer-height: 234px;
// フッター開閉ボタンの表示エリアの高さ
$footer-button-height: 45px;
// フッター表示領域の横幅の最大値(mobile)
$footer-mobile-max-width: 350px;
// フッター表示領域の横幅の最小値(mobile)
$footer-mobile-min-width: 300px;
