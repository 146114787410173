.not-found {
  display: flex;
  margin: 80px 30px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;

  & > p {
    margin: 0;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}
