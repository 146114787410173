$palette-boulder: #808080;
$palette-dodger-blue: #3ed7ff;
$palette-tundora: #404040;
$palette-mercury: #e6e6e6;
$palette-dark-dodger-blue: #19cbff;
$palette-gray: #d1d1d5;
$palette-azure-radiance: #0a84ff;
$palette-light-blue: #5a9fca;
$palette-cyan: #008ee7;
$palette-light-gray: #cccccc;
$palette-shark: #1e1e1e;
$palette-coral-red: #ff453a;
$palette-scorpion: #726463;
$palette-emerald: #4bd865;
$palette-lamp-black: #1c1c1d;
$palette-wet-crow: #0d0d0d;
$palette-silver-gray: #b3b3b3;
$palette-medium-gray: #2c2c2d;
$palette-cerulean: #00abe8;

// HTML基本色12色（グレースケール系なし）
// TODO：話者ボタンの色の詳細が決まったら変更する可能性あり
$palette-true-red: #ff0000;
$palette-true-maroon: #800000;
$palette-true-yellow: #ffff00;
$palette-true-olive: #808000;
$palette-true-lime: #00ff00;
$palette-true-green: #008000;
$palette-true-aqua: #00ffff;
$palette-true-teal: #008080;
$palette-true-blue: #0000ff;
$palette-true-navy: #000080;
$palette-true-fuchsia: #ff00ff;
$palette-true-purple: #800080;
$palette-true-white: #ffffff;
$palette-true-black: #000000;
