@use '../../../styles/colors.module' as colors;

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: colors.$color-button;
  border: 1px solid colors.$color-button-border;
  color: colors.$color-button-label;
  border-radius: 5px;
  padding: 12px 54px 12px 20px;
  text-align: center;
  font-size: 1.0625rem;
  line-height: 1.5rem;
  font-weight: 700;
  width: 330px;
  min-width: 240px;
  transition: transform 0.3s;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }
}

.disabled {
  pointer-events: none;
}
