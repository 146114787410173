@use '../../../styles/colors.module' as colors;

.link-button {
  display: inline-block;
  background-color: colors.$color-button;
  border: 5px solid colors.$color-button-border;
  color: colors.$color-button-label;
  border-radius: 18px;
  padding: 0.375rem 3rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  transition: transform 0.3s;
  text-decoration: none;
}

.disabled {
  pointer-events: none;
}
