@use '@/styles/colors.module' as colors;
@use '@/styles/palettes.module' as palettes;
@use '@/styles/styles.module' as styles;

.content {
  font-size: 24px;
  text-align: center;
  margin: 0 15px 0 20px;
  height: 100%;
}

.footer-close {
  height: 100%;
  max-height: calc(
    100% - styles.$footer-button-height - env(safe-area-inset-bottom)
  );
  transition: max-height 0.6s;
}

.footer-open {
  max-height: calc(
    100% - (styles.$footer-height + styles.$footer-button-height)
  );
  transition: max-height 0.6s;
}

.initial-state {
  color: palettes.$palette-true-white;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
}

.text > .one-phrase {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.conference {
  color: palettes.$palette-true-white;
  height: 100%;
}

.text > .list {
  display: block;
  height: 100%;
}

.text ul {
  list-style: none;
  padding-inline-start: 0;
  margin-block: 0;
}

.text ul > li {
  width: 100%;
  // RGB値は後でカラー定義ファイルに移行
  //   border-bottom: 0.5px solid var(--border-color);
  border-bottom: 0.5px solid var(#a3a3a3);
}

.text.conference ul > li {
  border: none;
}

.text ul.recognized > li:last-child {
  border-bottom: none;
}

.text > .list > .recognized > li {
  display: flex;
  flex-direction: row;

  & div {
    width: 45%;
    margin: 0 10px 0 10px;
  }
}

.interim > li {
  padding: 5px 0;

  &:empty {
    min-height: 28px;
  }

  & div {
    font-size: 12px;
    padding: 5px;
    background-color: rgba(217, 217, 217, 0.18);
    border-color: rgba(217, 217, 217, 0.18);
    border-radius: 3px;
    text-align: left;
    color: colors.$color-header-border;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
  }
}

.one-phrase {
  & .recognized {
    max-height: calc(100% - 40px);
  }
}

.list {
  & .recognized {
    max-height: calc(100% - 40px);
  }
}

.recognized {
  color: palettes.$palette-true-white;
  overflow-y: scroll;

  // firefoxのスクロールバー設定
  scrollbar-width: thin;
  scrollbar-color: colors.$color-list-view-scroll-bar rgba(0, 0, 0, 0.5);

  & li {
    padding: 3px 0;
  }

  & li > div {
    text-align: left;
    font-size: 1.8rem;
  }
}

.interim {
  color: colors.$color-list-view-interim-letter;
}

// 暫定テキストスクロールバーの設定
.raw::-webkit-scrollbar {
  display: none;
}

// 翻訳テキストスクロールバーの設定
.recognized::-webkit-scrollbar {
  width: 5px;
}

.recognized::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5);
}

.recognized::-webkit-scrollbar-thumb {
  background-color: colors.$color-list-view-scroll-bar;
  border-radius: 6px;
}
