@use '../../../styles/palettes.module' as palettes;
@use '../../../styles/colors.module' as colors;

.agreement {
  display: flex;
  flex-direction: column;
  padding: 80px 30px;
  align-items: center;
  flex: 1 0 0;
  gap: 25px;
}

.agreement-text-container {
  display: flex;
  min-width: 280px;
  max-width: 420px;
  flex-direction: column;
  align-items: center;
  gap: 35px;

  & p {
    margin: 0;
  }

  & .agreement-title {
    & > p {
      font-size: 1.75rem;
      line-height: 2rem;
    }
  }

  & .agreement-text {
    & > p {
      font-size: 0.875rem;
      line-height: 1.1rem;
    }
  }
}

.agreement-body {
  display: flex;
  min-width: 280px;
  max-width: 420px;
  flex-direction: column;
  justify-content: center;
  gap: 25px;

  & .term-of-service {
    text-align: center;

    & > a {
      text-decoration: none;
      display: inline-flex;
      align-items: center;
      gap: 4px;
      color: colors.$color-agreement-link;
      font-size: 1rem;
      line-height: 1.25rem;
    }
  }

  & button {
    // 利用規約画面内ボタン共通設定
    font-size: 1.0625rem;
    line-height: 1.5rem;
    font-weight: 700;
    width: 270px;
    min-width: 108px;
    height: 48px;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.agreement-checkbox {
  color: palettes.$palette-true-white;
  font-size: 0.875rem;
  line-height: 1.1rem;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  & > input[type='checkbox'] {
    display: none;
  }

  & > label {
    display: flex;
    padding: 3px 3px 3px 32px;
    align-items: center;
    position: relative;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 5px;
      width: 16px;
      height: 16px;
      border-radius: 3px;
      border: 2px solid palettes.$palette-true-white;
      transform: translateY(-50%);
    }
  }

  & > input[type='checkbox']:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    left: 11px;
    transform: rotate(45deg);
    width: 5px;
    height: 10px;
    border: solid palettes.$palette-true-white;
    border-width: 0 3px 3px 0;
  }
}

.agree-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  // ボタン毎に見た目が異なるため個別で設定する
  & button {
    background: palettes.$palette-azure-radiance;
    border: 0px;

    &:disabled {
      cursor: default;
      background: colors.$color-blue-button-disabled;
      color: colors.$color-blue-button-disabled-label;
    }
  }
}

.disagree-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  // ボタン毎に見た目が異なるため個別で設定する
  & button {
    border: 1px solid colors.$color-disagree-button-border;
    color: colors.$color-agreement-link;
    background: transparent;
  }
}

.agreement-form-error {
  & p {
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }
}
